import React, { Component } from 'react';
import Layout from '../components/layout';
import axios from 'axios';
const API = process.env.API;
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordConfirmation: '',
      password: '',
      success: false,
      error: false,
      message: '',
    }
  }

  submitResetPasswordForm(e){
    e.preventDefault();
    e.stopPropagation();
    const {password, passwordConfirmation} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if(password === passwordConfirmation){
      axios.post(`${API}/auth/reset-password`, {
        code,
        password,
        passwordConfirmation
      })
      .then(()=>{
        this.setState({
          message: "Your user's password has been reset.",
          passwordConfirmation: '',
          password: '',
          success: true,
          error: false, 
        })
      })
      .catch(()=>{
        this.setState({
          message: "An error occurred",
          success: false,
          error: true, 
        })
      })
    }
    else {
      this.setState({
        message: "Passwords don't match",
        success: false,
        error: true, 
      })
    }

    
  }
  render() {
    const {password, passwordConfirmation, success, error, message} = this.state;
    return (
      <Layout>
        <div className="py-10 container lg:py-0 min-h-screen text-brand-blue">
          <div className="flex items-center min-h-screen -mt-20 mx-auto max-w-sm w-full">
            <div className="w-full">
              <h1 className="text-3xl text-center mb-8">Reset Password</h1>
              <form onSubmit={this.submitResetPasswordForm.bind(this)}>
                <label className="text-sm font-semibold my-1 block" htmlFor="password">Password</label>
                <input
                  className="border rounded-lg p-2 block focus:outline-none focus:border-blue-500 mb-4 w-full" 
                  type="password" 
                  value={password} 
                  onChange={({target})=>{this.setState({password: target.value})}} 
                  name="password"/>
                <label className="text-sm font-semibold my-1 block" htmlFor="confirm-password">Confirm password</label>
                <input
                  className="border rounded-lg p-2 block focus:outline-none focus:border-blue-500 mb-4 w-full" 
                  type="password" 
                  value={passwordConfirmation} 
                  onChange={({target})=>{this.setState({passwordConfirmation: target.value})}} 
                  name="confirm-password"/>
                <button className="bg-brand-blue text-white w-full p-2 rounded-xl">
                  Reset Password
                </button>
              </form>
              <div className="mt-5 font-bold">
                {error&&
                  <div className="text-red-900 bg-red-100 p-2">{message}</div>
                }
                {success&&
                  <div className="text-green-900 bg-green-100 p-2">{message}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ResetPassword;